<!-- 题目总结 -->
<template>
  <div class="question-summary">

    <van-tabs type="card" v-model="curActive" v-if="solution.length > 1" color="#415FD5" class="solution-tabs">
      <van-tab
          v-for="(item, index) in solution"
          :key="index"
          :title="'解法' + numToChinese(index + 1)"
      />
    </van-tabs>


    <van-collapse v-model="activeNames">
      <!--   非真题步骤   -->
      <template v-if="!showStep">
        <van-collapse-item

            v-for="(item, index) in steps"
            :name="index"
            :key="index"
        >
          <template #title>
            <div class="title" v-html="renderTitle(item)"/>
          </template>
          <div class="content" v-html="renderContent(item)"/>
        </van-collapse-item>
      </template>

      <!--   真题步骤   -->
      <template v-else>
        <van-collapse-item

            v-for="(item, index) in solution[curActive]"
            :name="index"
            :key="index"
        >
          <template #title>
            <div class="title" v-html="renderTitle(item)"/>
          </template>
          <div class="content" v-html="renderContent(item)"/>
        </van-collapse-item>
      </template>

      <van-collapse-item>
        <template #title>
          <p class="summary-title">总结</p>
        </template>
        <div class="summary-content" v-html="renderSummary()"/>
      </van-collapse-item>

      <van-collapse-item v-if="questionStep === SUBMIT_TYPE.SUBMITTED" disabled>
        <template #right-icon>{{ '' }}</template>
        <template #title>
          <div class="tools">
            <template v-if="!openAllStep">
              <van-button type="primary" class="btn open" @click="handleOpen">
                展开步骤
              </van-button>
            </template>
            <template v-else>
              <van-button type="primary" class="btn open" @click="activeNames = []">
                收起步骤
              </van-button>
            </template>
            <van-button type="primary" class="btn next" @click="handleNext">下一题</van-button>
          </div>
        </template>
      </van-collapse-item>
      <van-collapse-item v-if="questionStep === SUBMIT_TYPE.SHORT_CONFIRM" disabled>
        <template #right-icon>{{ '' }}</template>
        <template #title>
          <div class="tools">
            <van-button type="primary" class="btn pass" @click="handleSetRet('y')">过关</van-button>
            <van-button type="primary" class="btn error" @click="handleSetRet('n')">还不够</van-button>
          </div>
        </template>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script type="application/javascript">
import katex from 'katex';
import { SUBMIT_TYPE } from '@/consts';
import { numToChinese } from '@/assets/js/utils'

export default {
  props: {
    solution: {
      type: Array,
      default: []
    },
    steps: {
      type: Array,
      default: []
    },
    summary: {
      type: Array,
      default: []
    },
    questionStep: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      SUBMIT_TYPE,
      activeNames: [],
      curActive: 0,
    }
  },
  computed: {
    showStep () {
      const {type} = this.$route.query
      return ['zhenti', 'chongzu', 'topic', 'collection'].includes(type)
    },
    openAllStep () {
      return this.activeNames.length > 0
    }
  },

  methods: {
    numToChinese,
    renderTitle (item) {
      return katex.renderToString(item[ '步骤' ], {
        throwOnError: false
      });
    },

    renderContent (item) {
      let step = ''
      item[ '解析' ][ 0 ].forEach(str => {
        step += str[ 1 ]
      });
      const containerDom = document.createElement('span');
      item[ '解析' ][ 0 ].forEach(str => {
        const contentDom = document.createElement('span');
        if (str[ 0 ] === 'str') {
          contentDom.className = 'tex_item'
          contentDom.innerHTML = str[ 1 ]
        } if (str[ 0 ] === 'img') {
          contentDom.className = 'tex_item'
          contentDom.innerHTML = "<img src=data:image/png;base64," + str[1] + " />"
        } else {
          contentDom.className = 'item_jiexi tex_item'
          contentDom.innerHTML = katex.renderToString(str[ 1 ], {
            throwOnError: false
          });
        }
        containerDom.appendChild(contentDom)
      });

      return containerDom.outerHTML
    },
    renderSummary () {
      const [type, str] = this.summary || [];
      if (type !== 'img') {
        return katex.renderToString(str, {
          throwOnError: false
        });
      }
      return "<img src=data:image/png;base64," + str + " />"
    },

    // 展开全部
    handleOpen () {
      this.activeNames = this.solution[this.curActive].map((i, index) => index);
    },
    // 下一题
    handleNext () {
      this.$emit('next');
    },

    handleSetRet (ret) {
      this.$emit('on-result', ret)
    }
  }
}
</script>

<style lang="less" scoped>
.question-summary {
  box-shadow: 0px 1px 8px 0px rgba(42, 49, 85, 0.1);
  border-radius: 12px !important;

  .title {
    min-height: 40px;
    line-height: 40px;

    /deep/ .katex {
      font-size: 14px;
      color: #2A3155;
      line-height: 19px;
    }
  }

  .content, .summary-content {
    overflow-x: scroll;
    text-align: left;
    /deep/ .katex {
      line-height: 19px;
    }
  }

  .summary-title {
    height: 40px;
    font-size: 14px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #415FD5;
    line-height: 40px;
    text-align: center;
  }

  .btn {
    height: 40px;
    border-radius: 24px;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 40px;
    border-color: transparent;

    &.next {
      width: 104px;
      background: #415FD5;
    }

    &.open {
      width: 191px;
      background: #F9C200;
      margin-right: 16px;
    }

    &.pass {
      width: 104px;
      background: #15C392;
      margin-right: 16px;
    }

    &.error {
      width: 104px;
      background: #FF4236;
    }
  }

  /deep/ .item_jiexi {
    display: inline-block;
  }

  .solution-tabs {
    padding: 12px 0;
    background: white;
    border-radius: 12px 12px 0 0;

    /deep/ .van-tabs__nav {
      border-radius: 4px;
    }
  }

  /deep/ .van-cell__title {
    text-align: left;
  }
}
.tools {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}
</style>
