<!--填空题-->
<template>
  <div class="fill">
    <question-header
        :question="question"
        :title="title"
        :question-index="questionIndex"
    />

    <div class="pt_12 tibox ta_l fsz_18" id="fillStem" style="overflow-x: scroll">
      <span>{{ questionIndex[ 0 ] }}、</span>
      <template v-for="(item,n) in question['例题']">
        <span :key="n" v-if="item[0]=='str'" class="item">{{ item[ 1 ] }}</span>
        <span :key="n" v-else-if="item[0]=='tex'" class="tex_item" :id="'tex_item_'+n"></span>
        <span :key="n" v-else-if="item[0]=='img'" class="tex_item" :id="'tex_item_'+n">
          <img :src="'data:image/png;base64,'+item[1]" alt="">
        </span>
      </template>
    </div>

    <template v-if="questionStep === SUBMIT_TYPE.TO_SUBMIT">
      <submit-tools @submit="handleSubmit" :tips="[]"/>
    </template>

    <template v-if="questionStep === SUBMIT_TYPE.SUBMITTED">
      <question-summary
          :steps="question['分步'] || []"
          :summary="question['总结']"
          :solution="question['分步解析']"
          @next="handleNextQuestion"
          :question-step="questionStep"
      />

      <div class="h16"/>
      <test-site :site="question['考点']" has-title border/>
    </template>
  </div>
</template>

<script type="application/javascript">
var $ = require("jquery");
window.$ = $;

import TestSite from '../TestSite';
import QuestionSummary from '../QuestionSummary'
import QuestionHeader from '../QuestionHeader';
import SubmitTools from '../SubmitTools';

import questionMixin from '@/mixins/questionMixin';
import submitMixin from '@/mixins/submitMixin';
import { SUBMIT_TYPE } from '@/consts';

export default {
  components: {TestSite, QuestionSummary, QuestionHeader, SubmitTools},
  mixins: [questionMixin, submitMixin],
  props: {
    question: {
      type: Object,
      default: {}
    },
    title: {
      type: String,
      default: ''
    },
    questionIndex: {
      type: Array,
      default: [0, 0]
    }
  },
  computed: {},
  data () {
    return {
      SUBMIT_TYPE,
      questionStep: SUBMIT_TYPE.TO_SUBMIT,
      isRight: 'false'
    }
  },
  methods: {
    handleSubmit () {
      let result = 'y'
      let isKong = false;
      let dataArr = []
      const inputClass = $('#fillStem .inputClass');
      inputClass.map((n, e) => {
        if (e.value === '') {
          isKong = true;
        }
      })
      if (isKong) {
        this.$toast('请填写答案！')
        return false;
      }
      inputClass.map((n, e) => {
        dataArr.unshift(e.value.toString());
      })

      this.question[ "解" ].map((e, i) => {
        inputClass.map((n, el) => {
          if (el.value != e && i == n) {
            if (el.value === '') {
              el.value = "?"
            }
            result = 'n'
            $(el).before('<span class="jie-err">' + el.value + '</span>')
          } else if (el.value == e && i == n) {
            $(el).before('<span class="jie-succ">' + e + '</span>');
          }
        })
        inputClass.hide()
      });

      if (result === 'n') {
        inputClass.after('<span>(' + this.question['解'][0] + ')</span>')
      }

      const ret = this.submit({
        exam_status: result,
      });
      if (ret) {
        this.questionStep = SUBMIT_TYPE.SUBMITTED
      }
    },

    // 下一题
    handleNextQuestion () {
      this.$emit('next-question', this.isRight)
    }
  },
  mounted () {
    this.renderQuestionStem(this.question[ '例题' ]);
    this.$nextTick(() => {
      let mcloseArr = $('#fillStem').find('.cjk_fallback');
      $.each(mcloseArr, function (index) {
        let item = mcloseArr[ index ];
        if ($(item).html() == "冇") {
          $(item).html("<input type='text' class='inputClass'>")
        }
      });
    });
  }
}
</script>

<style lang="less" scoped>
.stem {
  text-align: left;
  margin-bottom: 16px;

  /deep/ &-index {
    font-size: 16px;
  }

  /deep/ .katex {
    line-height: 40px;
    font-size: 18px;
  }
}

/deep/ .formula-input {
  width: 64px;
  height: 32px;
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #B9BFD3;
  margin-left: 4px;

  &::placeholder {
    font-size: 14px;
    color: #BFC1CC;
    padding: 6px;
  }
}

.h16 {
  height: 16px;
}
</style>
