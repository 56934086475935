<!--选择题-->
<template>
  <div class="select">
    <question-header
        :question="question"
        :title="title"
        :question-index="questionIndex"
    />

    <div class="pt_12 tibox ta_l fsz_18" style="overflow-x: scroll">
      <span>{{ questionIndex[0] }}、</span>
      <template v-for="(item,n) in question['例题']">
        <span :key="n" v-if="item[0]=='str'" class="item">{{item[1]}}</span>
        <span :key="n" v-else-if="item[0]=='tex'" class="tex_item" :id="'tex_item_'+n"></span>
        <span :key="n" v-else-if="item[0]=='img'" class="tex_item" :id="'tex_item_'+n">
          <img :src="'data:image/png;base64,'+item[1]" alt="">
        </span>
      </template>
    </div>

    <!--  是否展示全部  -->
    <div v-show="showAllOptions">
      <div class="select-option" ref="optionRef"/>

      <van-icon
          v-if="questionStep === SUBMIT_TYPE.SUBMITTED"
          name="arrow-up"
          @click="showAllOptions = false"
      />
    </div>
    <div class="selected-option" v-show="!showAllOptions">
      <div class="selected-option__content" ref="selectedOption"/>

      <van-icon name="arrow-down" @click="handleVisibleOptions"/>
    </div>

    <template v-if="questionStep === SUBMIT_TYPE.TO_SUBMIT">
      <submit-tools @submit="handleSubmit" :tips="[]"/>
    </template>

    <template v-if="questionStep === SUBMIT_TYPE.SUBMITTED">
      <question-summary
          :steps="question['分步'] || []"
          :summary="question['总结']"
          :solution="question['分步解析']"
          @next="handleNextQuestion"
          :question-step="questionStep"
      />

      <div class="h16"/>
      <test-site :site="question['考点']" has-title border/>
    </template>

  </div>
</template>

<script type="application/javascript">
import katex from 'katex';
import QuestionSummary from '../QuestionSummary';
import QuestionHeader from '../QuestionHeader';
import SubmitTools from '../SubmitTools';
import TestSite from '../TestSite';
import { Toast } from 'vant';

import questionMixin from '@/mixins/questionMixin';
import submitMixin from '@/mixins/submitMixin';
import { SUBMIT_TYPE } from '@/consts';

export default {
  components: {TestSite, QuestionSummary, QuestionHeader, SubmitTools},
  mixins: [questionMixin, submitMixin],
  props: {
    question: {
      type: Object,
      default: {}
    },
    title: {
      type: String,
      default: ''
    },
    questionIndex: {
      type: Array,
      default: [0, 0]
    }
  },
  data () {
    return {
      SUBMIT_TYPE,
      questionStep: SUBMIT_TYPE.TO_SUBMIT,
      curSelectIndex: null, // 当前选择的序号
      showAllOptions: true, // 是否展示全部
      isRight: 'false', // 答题结果s
    }
  },
  methods: {
    handleSubmit () {
      if (this.curSelectIndex === null) {
        Toast('请选择!');
        return;
      }
      this.checkAnswer();
    },

    // 检查正确性，流转至下一步
    async checkAnswer () {
      const answer = this.question[ '解' ];
      const result = answer[ this.curSelectIndex ]
      const ret = this.submit({
        exam_status: result ? 'y' : 'n'
      });
      this.isRight = result ? 'true' : 'false';
      if (ret) {
        this.questionStep = SUBMIT_TYPE.SUBMITTED
        this.renderSelectedOptions();


        this.$refs.optionRef.children.forEach((child, childIndex) => {
          if (childIndex === this.curSelectIndex) {
            const isRight = this.question[ '解' ][ childIndex ] || false;
            child.classList.add(isRight ? 'right': 'error');
          }
          if (this.question[ '解' ][ childIndex ]) {
            child.classList.add('right');
          }
        });
      }
    },

    // 展示全部选项
    handleVisibleOptions () {
      this.showAllOptions = true
      const selectIndex = this.curSelectIndex;
      const isRight = this.question[ '解' ][ selectIndex ] || false;
      this.$refs.optionRef.childNodes.forEach((child, index) => {
        if (selectIndex === index) {
          child.classList.add(isRight ? 'right': 'error');
        }
      });
    },

    handleSelectOption (item, index) {
      if (this.questionStep !== SUBMIT_TYPE.TO_SUBMIT) {
        return
      }
      // 移除已有的className
      console.log('========>', Array.isArray(this.$refs.optionRef.children))
      this.$refs.optionRef.children.forEach((child, childIndex) => {
        child.classList.remove('option-active');
        if (index === childIndex) {
          child.classList.add('option-active');
          this.curSelectIndex = childIndex;
        }
      });
    },

    renderSelectedOptions () {
      const selectIndex = this.curSelectIndex;
      const optionHeader = ['A', 'B', 'C', 'D'];
      const isRight = this.question[ '解' ][ selectIndex ] || false;
      const stem = this.question[ '选项' ][ selectIndex ] || false;
      if (stem) {
        const indexDom = document.createElement('span');
        indexDom.className = 'stem-index';
        indexDom.innerText = `${optionHeader[ selectIndex ]}、`
        this.$refs.selectedOption.appendChild(indexDom)

        const spanDom = document.createElement('span');
        katex.render(stem, spanDom, {
          throwOnError: false
        });
        this.$refs.selectedOption.appendChild(spanDom);
        if (isRight) {
          this.$refs.selectedOption.classList.add('right');
        } else {
          this.$refs.selectedOption.classList.add('error');
        }
      }
    },

    // 下一题
    handleNextQuestion () {
      this.$emit('next-question', this.isRight);
    }
  },

  mounted () {
    this.renderQuestionStem(this.question[ '例题' ])
    this.renderSelectOption(this.question[ '选项' ], this.$refs.optionRef)
  }
}
</script>

<style lang="less" scoped>
.fillStem {
  overflow-x: scroll;
}
.stem {
  text-align: left;

  /deep/ &-index {
    font-size: 16px;
  }

  /deep/ .katex {
    line-height: 40px;
    font-size: 18px;
  }
}

/deep/ .select-option {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;

  .option-active {
    border-color: #4461cf;
  }

  /deep/ &__item {
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #E6E8EF;
    display: flex;
    flex-flow: row;
    padding: 6px 12px;
    margin-bottom: 16px;
    width: 321px;

    > span {
      font-size: 16px !important;
      line-height: inherit;
      margin-right: 8px !important;
    }

    /deep/ .katex {
      font-size: 16px;
      font-weight: 400;
      color: #2A3155;
      line-height: 28px;
    }
  }

  .right {
    border: 1px solid #15C392;
    color: #15C392;

    /deep/ .katex {
      color: #15C392;
    }
  }

  .error {
    border: 1px solid #ec5444;
    color: #ec5444;

    /deep/ .katex {
      color: #ec5444;
    }
  }

  &__item:last-child {
    margin-bottom: 0px;
  }
}

.selected-option {
  &__content {
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid transparent;
    text-align: left;
    padding: 6px 16px;
    margin-bottom: 12px;
    margin-top: 16px;
  }

  .right {
    border: 1px solid #15C392;
    color: #15C392;
  }

  .error {
    border: 1px solid #ec5444;
    color: #ec5444;
  }
}

.h16 {
  height: 16px;
}
</style>
