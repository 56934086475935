<template>
  <div>
    <div class="question" v-if="question">
      <template v-if="question['答题方式'] === QUESTION_TYPE.SELECT">
        <select-question
            :question="question"
            :title="questionTitle"
            :question-index="questionIndex"
            @next-question="handleNextQuestion"
        />
      </template>
      <template v-if="question['答题方式'] === QUESTION_TYPE.FILL">
        <fill-question
            :question="question"
            :title="questionTitle"
            :question-index="questionIndex"
            @next-question="handleNextQuestion"
        />
      </template>
      <template v-if="question['答题方式'] === QUESTION_TYPE.SHORT_ANSWER">
        <short-answer
            :question="question"
            :title="questionTitle"
            :question-index="questionIndex"
            @next-question="handleNextQuestion"
        />
      </template>
      <template v-if="question['答题方式'] === QUESTION_TYPE.ASSIST_FILL">
        <assist-fill
            :question="question"
            :title="questionTitle"
            :question-index="questionIndex"
            @next-question="handleNextQuestion"
        />
      </template>

      <!-- <tools-bar :is-collection="isCollection" @click="handleTool"/> -->

      <div class="directory-nav" @click="directoryVisible = !directoryVisible">
        <p>目录</p>
      </div>

      <!-- nav -->
      <van-popup
        v-model="directoryVisible"
        closeable
        position="left"
        :style="{ width: '8.75rem',height: '100%' }">
        <div class="nav-con">
          <ul>
            <li v-for="(item, n) in nav_list" :key="n" @click="navClick(item,n)">
              <div class="nav-list-con  p_16 " :class="n==navCount?'on':''">
                {{ n+1 }}
              </div>
            </li>
          </ul>
        </div>

      </van-popup>

      <van-popup v-model="testSiteVisible" position="bottom" :style="{ height: '50%' }">
        <div class="test-site-header">
          <p>本题考点</p>
          <van-icon @click="testSiteVisible = false" class="icon" name="cross"/>
        </div>

        <test-site :site="question['考点']"/>
        <div style="height: 24px; border-top: 1px solid #E6E8EF"/>
      </van-popup>

      <van-overlay :show="collectionVisible" @click="collectionVisible = false">
        <div class="collection-dialog" @click.stop>
          <div class="collection-dialog__header">
            <img src="../../assets/img/collectionStar.png"/>
            <p>收藏成功!</p>
          </div>
          <div class="collection-dialog__tag">
            <p>可以给题目添加一个标签</p>
            <div class="tags">
              <div
                  v-for="item in QUESTION_TAG"
                  :key="item.key"
                  @click="handleSelectTag(item.value)"
                  :class="tags.includes(item.value) ? 'active' : ''"
              >{{ item.label }}
              </div>
            </div>

            <van-field :maxlength="10" v-model="customTag" placeholder="其他，点击输入" border center/>

            <van-button class="button" @click="handleCollection" :loading="tagLoading">完成</van-button>
          </div>
        </div>
      </van-overlay>
    </div>
    <van-overlay :show="queryLoading">
      <div class="wapper">
        <van-loading type="circular" vertical>
          加载中...
        </van-loading>
      </div>
    </van-overlay>
  </div>
</template>

<script type="application/javascript">
import DirectoryPopup from './component/DirectoryPopup';
import SectionCard from '@/components/SectionCard';
import ToolsBar from './component/ToolsBar';
import TestSite from './component/TestSite';
import { Toast, Dialog } from 'vant';

import {
  TOOLS_TYPE_ENUM,
  PAPER_TYPE,
  QUESTION_TYPE,
  QUESTION_TAG,
  REFACTOR_MODE,
  MATH_TYPE,
  BOOK_TYPE
} from '@/consts';

import SelectQuestion from './component/type/SelectQuestion';
import FillQuestion from './component/type/CompletionQuestion';
import ShortAnswer from './component/type/ShortAnswer';
import AssistFill from './component/type/AssistFill';

import directoryMixin from '@/mixins/directoryMixin';
import shareMixin from "@/mixins/shareMixin";

/*
* 路由携带参数信息
* type: PAPER_TYPE, // 试卷类型
* activeChapter // 当前章
* activeSection // 当前节
* activeQuestion // 当前题
* */
const TITLE_TYPE = {
  [ PAPER_TYPE.REAL_QUESTION ]: '真题精讲精练',
  [ PAPER_TYPE.REFACTOR_REAL ]: '真题重组测验',
  [ PAPER_TYPE.REFACTOR_TOPIC ]: '专题重组训练',
  [ PAPER_TYPE.COLLECTION ]: '我的收藏'
}


export default {
  components: {
    TestSite,
    SelectQuestion,
    DirectoryPopup,
    ToolsBar,
    FillQuestion,
    ShortAnswer,
    SectionCard,
    AssistFill
  },
  mixins: [
    directoryMixin, 
    shareMixin
  ],
  data () {
    return {
      BOOK_TYPE,
      QUESTION_TYPE,
      QUESTION_TAG,
      PAPER_TYPE,
      REFACTOR_MODE,
      queryLoading: true,
      question: null,
      routeParams: {
        type: '',
      },
      directoryVisible: false,
      testSiteVisible: false,
      collectionVisible: false, // 收藏弹框

      activeChapter: null,
      activeSection: null,
      activeQuestion: null,

      tags: [],
      customTag: '',
      tagLoading: false,


      navStatus: false,
      nav_list: [],
      navCount: 0,
    }
  },
  computed: {
    // 真题重组目录标题
    realQuestionRefactorTitle () {
      const {level, year, mathType, book} = this.$route.query
      return {
        level: REFACTOR_MODE.find(i => i.value === level)?.label,
        year,
        mathType: MATH_TYPE.find(i => i.value === mathType)?.label,
        book
      }
    },

    // 目录名称
    directoryTitle () {
      const {type} = this.routeParams
      return TITLE_TYPE[ type ] || ''
    },

    // 题目头
    questionTitle () {
      if (!this.directory.chapters) {
        return ''
      }
      const chapterTitle = this.directory?.chapters[ this.activeChapter ]?.chapterName || ''
      const sectionTitle = this.directory?.chapters[ this.activeChapter ]?.sections[ this.activeSection ]?.sectionName || ''
      return `${chapterTitle}-${sectionTitle}`
    },
    // 当前题目序号
    questionIndex () {
      if (!this.directory.chapters) {
        return [0, 0]
      }
      const count = this.directory.chapters[ this.activeChapter ]?.sections[ this.activeSection ].questions.length || 0
      return [this.activeQuestion + 1, count]
    },

    // 是否收藏
    isCollection () {
      // const curQuestion = this.directory.chapters[ this.activeChapter ]?.sections[ this.activeSection ].questions[ this.activeQuestion ] || {}
      // return !!curQuestion.is_favorite
      return false
    },

    // 题目标签
    questionFavorite () {
      console.log('curQuestion', 123, this.activeChapter, this.activeSection)
      console.log('this.directory.chapters', this.directory.chapters)
      // const curQuestion = this.directory.chapters[ this.activeChapter ]?.sections[ this.activeSection ].questions[ this.activeQuestion ] || {}
      // console.log('curQuestion', curQuestion)
      // if (!!curQuestion.is_favorite) return curQuestion.is_favorite.split(',')
      return []
    },

    curQuestion () {
      const question = this.directory.chapters[ this.activeChapter ]?.sections[ this.activeSection ].questions[ this.activeQuestion ] || {}
      return question
    },
  },
  methods: {
    handleTool (key) {
      if (key === TOOLS_TYPE_ENUM.DIRECTORY) {
        this.directoryVisible = true
        return
      }
      if (key === TOOLS_TYPE_ENUM.SITE) {
        this.testSiteVisible = true
        return
      }
      if (key === TOOLS_TYPE_ENUM.COLLECTION) {
        this.handleShowCollection()
        return
      }
    },
    async queryQuestion () {
      console.log('this.routeParams.questionID', this.routeParams.questionID)
      try {
        const ret = await this.$api.queryQuestion({
          param: {
            questionID: this.routeParams.questionID
          }
        });
        ret['难度'] && (ret['难度'] = +ret['难度'])
        this.question = ret
        
        console.log('this_label', ret)
        // this.$nextTick(()=>{
        //   setTimeout(() => {
        //     this.directory && this.directory.chapters && this.directory.chapters[0].sections.map((e,n)=>{
              
        //       e.questions && e.questions.map((el,i)=>{
        //         if(el.questionID == this.routeParams.questionID){
        //           this.activeSection = parseInt(n)
        //           localStorage.questionID = el.questionID
        //         }
        //       })
        //     })
        //   }, 10);
        // })
        
      } catch (err) {
        console.error(err)
      }
    },

    // 切换展开目录
    handleOpenSection (index) {
      this.activeSection = index
    },


    handleQuestionClick (chapterIndex, sectionIndex, questionIdx, question) {
      this.$store.state.realQuestion.curQuestionIdx = questionIdx
      this.$store.state.realQuestion.curQuestionId = question.questionID
      this.$router.push({
        path: '/question',
        query: {
          ...this.routeParams,
          isFirstView: 'false',
          chapterIndex,
          sectionIndex,
          questionIdx,
          questionID: question.questionID,
        }
      })
    },

    init () {
      this.routeParams = this.$route.query;
      const {chapterIndex, sectionIndex, questionIdx} = this.routeParams
      this.activeChapter = parseInt(chapterIndex)
      this.activeSection = parseInt(sectionIndex)
      this.activeQuestion = parseInt(questionIdx)
      this.nav_list = localStorage.getItem('questionIDList')?.split(',');
    },

    // 选择收藏标签
    handleSelectTag (tag) {
      if (this.tags.includes(tag)) {
        this.tags = this.tags.filter(t => t !== tag)
      } else {
        this.tags.push(tag);
      }
    },

    // 是否收藏
    handleShowCollection () {
      if (!this.isCollection) {
        this.tags = []
        this.customTag = ''
        this.collectionVisible = true
      } else {
        Dialog.confirm({
          title: '提示',
          message: '是否取消收藏',
        }).then(async () => {
          await this.$api.cancelCollection({
            param: {
              questionID: this.curQuestion.questionID
            }
          });
          this.$store.commit('realQuestion/updateQuestionInfo', {
            chapterIdx: this.activeChapter,
            sectionIdx: this.activeSection,
            questionIdx: this.activeQuestion,
            params: {
              is_favorite: ""
            }
          });
          Toast.success('取消成功');
        }).catch(() => {
        });
      }
    },

    // 收藏
    async handleCollection () {
      this.tagLoading = true
      try {
        const labels = this.customTag ? [...this.tags, this.customTag].join(','): [...this.tags].join(',')
        await this.$api.pushCollection({
          param: {
            labels,
            questionID: this.curQuestion.questionID
          }
        });
        this.collectionVisible = false
        Toast.success('收藏成功');
        this.$store.commit('realQuestion/updateQuestionInfo', {
          chapterIdx: this.activeChapter,
          sectionIdx: this.activeSection,
          questionIdx: this.activeQuestion,
          params: {
            is_favorite: labels
          }
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.tagLoading = false
      }
    },

    // 下一题
    handleNextQuestion (result) {
      const questionCount = this.questionIndex[ 1 ]

      const param = [this.activeChapter, this.activeSection, this.activeQuestion]

      if (questionCount === this.activeQuestion + 1) {
        param[ 2 ] = 0
        param[ 1 ] = this.activeSection + 1

        const sectionNum = this.directory.chapters[ this.activeChapter ]?.sections.length
        if (sectionNum < this.activeSection + 2) {
          param[ 1 ] = 0
          param[ 0 ] = this.activeChapter + 1

          const chaptersNum = this.directory.chapters.length
          if (chaptersNum < this.activeChapter + 2) {
            console.log('最后一道题');
            return;
          }
        }
      } else {
        param[ 2 ] = this.activeQuestion + 1
      }
       this.$store.commit('realQuestion/updateQuestionInfo', {
          chapterIdx: this.activeChapter,
          sectionIdx: this.activeSection,
          questionIdx: this.activeQuestion,
          params: {
            isRight: result
          }
        });
      const question = this.directory.chapters[ param[ 0 ] ]?.sections[ param[ 1 ] ].questions[ param[ 2 ] ] || {}
      this.handleQuestionClick(param[ 0 ], param[ 1 ], param[ 2 ], question);
    },

    relate_exam(id){
      console.log('query', id);
      this.$api.queryQuestion({
        param: {
          questionID: id
        }
      }).then(res=>{
        this.question = res;
      });
    },
    navClick(item, n){
      this.navCount = n
      this.directoryVisible = false
      this.routeParams.questionID = item;
      this.queryQuestion()
    }
  },
  async mounted () {
    this.init();
    this.initShare()
    this.queryQuestion();

    if (this.$route.query.isFirstView !== 'false') {
      this.queryDirectory();
    } else {
      this.queryLoading = false
    }
  }
}
</script>

<style lang="less" scoped>
.question {
  padding: 16px 16px 66px;
  background-color: #F7F8FB;
  min-height: calc(100vh - 92px);

  .test-site-header {
    position: relative;

    > p {
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #0B2432;
      line-height: 25px;
      padding: 17px 0;
    }

    .icon {
      position: absolute;
      top: 20px;
      right: 16px;
    }
  }
}

.directory-content {
  margin-top: 26px;
}

.collection-dialog {
  width: 327px;
  margin: 10% auto;

  &__header {
    background-image: url("../../assets/img/collectionBanner.png");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 129px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    > img {
      width: 52px;
      height: 52px;
      margin-bottom: 12px;
    }

    > p {
      height: 24px;
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 24px;
    }
  }

  &__tag {
    padding: 24px 24px 21px;
    background-color: white;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    > p {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #606580;
      margin-bottom: 26px;
    }

    .tags {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      > div {
        width: 88px;
        height: 36px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #E6E8EF;
        line-height: 36px;
        margin-bottom: 12px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #2A3155;
      }

      .active {
        border-color: #415FD5;
        color: #415FD5;
      }
    }

    /deep/ .van-field {
      border: 1px solid #E6E8EF;
    }

    /deep/ .van-field__control::placeholder {
      text-align: center;
    }

    /deep/ .van-field__control {
      text-align: center;
    }

    .button {
      width: 279px;
      height: 40px;
      background: #415FD5;
      border-radius: 24px;
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 40px;
      margin-top: 16px;
    }
  }
}

.filter-desc {
  margin-bottom: 16px;

  > p {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606580;
    line-height: 20px;
    margin-bottom: 8px;
  }
}

.wapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}



.nav-con {
  ul {
    margin-top: 50px;
    li {
      .nav-list-con {
        width: 248px;
        min-height: 51px;
        background: rgba(65, 95, 213, 0.1);
        border-radius: 12px;
        box-sizing: border-box;
        margin-left: 16px;
        margin-bottom: 12px;

        &.on {
          color: #fff;
          background: #415FD5;

          .jindu {
            width: 100%;
          }
        }

        .list-con {
          display: flex;
          flex-wrap: wrap;
          max-height: 135px;
          overflow: auto;

          span {
            width: 32px;
            height: 32px;
            line-height: 32px;
            border-radius: 32px;
            margin: 10px;
            color: #fff;
            text-align: center;
            border: 1px solid #fff;

            &.on {
              color: #fff;
              background: #F9C200;
              border: 1px solid #F9C200;
            }

            &.err {
              color: #fff;
              background: red;
              border: 1px solid red;
            }

            &.succ {
              // color: #415FD5;
              background: #07c160;
              border: 1px solid #07c160;
            }
          }

          .special {
            width: 89px;
          }
        }
      }

      .jindu {
        // margin-top: 12px;
        width: 60px;
        height: 8px;
        background: #fff;
        border-radius: 5px;
        display: inline-block;
        // overflow: hidden;
        position: relative;

        .pair {
          background: #F9C200;
          position: absolute;
          border-radius: 5px;
          top: 0.5px;
          left: 0px;
          height: 8px;
        }
      }
    }
  }
}



.directory-nav {
  position: fixed;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow: 0px 2px 12px rgba(42, 49, 85, 0.15);
  writing-mode: tb-rl;
  left: -24px;
  bottom: 153px;
  background: white;

  > p {
    text-align: center;
    letter-spacing: 6px;
    padding: 6px 8px 0 0;
    color: #4461ce;
    font-weight: 500;
  }
}


</style>