<!-- 题目顶部 -->
<template>
  <div class="question-header">
    <div class="header">
      <div class="left">
        <div class="out" @click="$router.push('/directory/realQuestion')">
          <img src="../../../assets/img/realQuestion/left-arrow.png"/>
          <p>退出</p>
        </div>
        <p>{{ question[ '来源' ] }}</p>
      </div>
      <van-rate
          v-model="+question['难度']"
          readonly
          :size="14"
          color="#ffd21e"
          void-icon="star"
          void-color="#eee"
      />
    </div>

    
  </div>
</template>

<script type="application/javascript">
export default {
  props: {
    question: {
      type: Object
    },
    questionIndex: {
      type: Array,
      default: [0, 0]
    }
  }
}
</script>

<style lang="less" scoped>
.question-header {
  .header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    margin-bottom: 4px;

    .left {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;

      > p {
        color: #606580;
      }

      .out {
        color: #4461ce;
        margin-right: 16px;
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;

        > img {
          width: 18px;
        }
      }
    }


    > div {

      color: #415FD5;
    }
  }

  .level {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;

    > p {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #606580;
    }
  }

  .favorite {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;

    /deep/ .van-tag {
      margin-right: 4px;
    }
  }
}
</style>
