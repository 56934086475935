<!--底部操作栏-->
<template>
  <div class="container">
    <div class="tools-bar">
      <div
          v-for="item in tools"
          @click="handleClick(item)"
          class="tools-bar__item"
          :key="item.key"
      >
        <template v-if="item.key === TOOLS_TYPE_ENUM.COLLECTION && isCollection">
          <van-icon name="star" class="tools-bar__item-icon is-collection" />
          <p class="is-collection-p">{{ item.name }}</p>
        </template>
        <template v-else>
          <van-icon :name="item.icon" class="tools-bar__item-icon"/>
          <p>{{ item.name }}</p>
        </template>
      </div>
    </div>
  </div>
</template>

<script type="application/javascript">
import { TOOLS_TYPE_ENUM, TOOLS_TYPE_S } from '@/consts'

export default {
  props: {
    isCollection: {
      type: Boolean
    }
  },
  data () {
    return {
      TOOLS_TYPE_ENUM,
      TOOLS_TYPE_S
    }
  },
  computed: {
    tools () {
      const { type } = this.$route.query
      if (type === 'zhenti') {
        return TOOLS_TYPE_S.filter(item => item.key !== TOOLS_TYPE_ENUM.GUIDE)
      }
      return TOOLS_TYPE_S
    }
  },
  methods: {
    handleClick (item) {
      this.$emit('click', item.key);
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  position: fixed;
  bottom: 0;
  left: 16px;
  right: 16px;
  display: flex;
  justify-content: center;
}

.tools-bar {
  height: 32px;
  background: #FFFFFF;
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 32px;
  margin-bottom: 16px;

  &__item {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-right: 40px;

    &-icon {
      font-size: 16px;
      color: #707693;
    }

    > p {
      font-size: 12px;
      font-family: SourceHanSansSC-Normal, SourceHanSansSC;
      font-weight: 400;
      color: #2A3155;
    }

    .is-collection {
      color: #F9C200;
    }
    .is-collection-p {
      color: #9498AA;
    }
  }

  &__item:last-child {
    margin-right: 0;
  }
}
</style>
