<!-- 题目提交按钮组 -->

<template>
  <div class="submit-tools">
    <div class="submit btn" @click="handleSubmit">提交</div>
  </div>
</template>

<script type="application/javascript">

export default {
  props: {
    tips: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      showPopover: false,
    }
  },
  methods: {
    handleSubmit () {
      this.$emit('submit');
    }
  }
}
</script>

<style lang="less" scoped>
.submit-tools {
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin-top: 16px;

  .btn {
    height: 40px;
    border-radius: 24px;
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 40px;
  }
  .submit {
    background: #F9C200;
    margin-right: 12px;
    width: 221px;
  }
  .tips {
    background: #415FD5;
    width: 150px;
    border: none;
  }
}

.tips-content {
  width: 343px;
}
</style>
