<!-- 抽屉目录 -->

<template>
  <van-popup
      v-model="visible"
      position="left"
      :style="{ width: '75%', height: '100%' }"
      @click-overlay="handleClose"
  >
    <div class="directory-popup">
      <div class="header">
        <p class="title">{{ title }}</p>

        <div class="close">
          <van-icon name="cross" @click="handleClose"/>
        </div>
      </div>
      <slot/>
    </div>
  </van-popup>
</template>

<script type="application/javascript">
export default {
  props: {
    directory: {
      type: Array
    },
    visible: {
      type: Boolean
    },
    title: {
      type: String
    }
  },
  methods: {
    handleClose () {
      this.$emit('close');
    },
    goPath () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="less" scoped>
.directory-popup {
  height: 100%;
  position: relative;
  text-align: left;
  padding: 0 16px;

  .header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }

  .close {
    font-size: 22px;
    color: rgba(52, 60, 101, 0.7);
    margin-top: 12px;
  }

  .title {
    font-size: 22px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #2A3155;
  }
}
</style>
