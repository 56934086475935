<!-- 考点组件 -->
<template>
  <div class="test-site">
    <!-- <p class="title" v-if="hasTitle">考点训练</p>

    <div :class="`card ${border ? 'border' : ''}`">
      <div class="card__item" v-for="(item, index) in site" :key="index">
        <p class="card__item-title" v-html="renderTitle(item['考点名'])" />
        <div class="card__item-tools">
          <div class="btn real-question" @click="zhenti(item)">刷真题</div>
          <div class="btn basis " @click="relate_exam(item)">练基础</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script type="application/javascript">
import { PAPER_TYPE, MATH_TYPE_ENUM } from "@/consts";
import katex from 'katex';

export default {
  props: {
    hasTitle: {
      type: Boolean,
      default: false
    },
    site: {
      type: Array,
      default: []
    },
    border: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      PAPER_TYPE,
      MATH_TYPE_ENUM
    }
  },
  methods: {
    zhenti(item) {
      this.$router.push({
        path: '/zhenti',
        query: {
          list: item['索引'].join()
        }
      })
    },
    handlePractise (row) {
      const [book, chapterIndex, sectionIndex, questionIdx, questionID] = row['索引']
      this.$store.state.realQuestion.curQuestionIdx = questionIdx
      this.$store.state.realQuestion.curQuestionId = questionID
      this.$router.push({
        path: '/question',
        query: {
          chapterIndex,
          sectionIndex,
          questionIdx,
          questionID,
          type: PAPER_TYPE.REFACTOR_TOPIC,
          mathType: MATH_TYPE_ENUM.MATH_ONE,
          book
        }
      })
    },
    renderTitle (text) {
      return katex.renderToString(text, {
        throwOnError: false
      });
    },
    relate_exam (item) {
      console.log('item', item)
      this.$router.push({
        path: '/relate_exam',
        query: {
          name: item['考点名']
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.test-site {
  .title {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2A3155;
    line-height: 20px;
    text-align: left;
    margin-bottom: 8px;
  }
  .border {
    box-shadow: 0px 1px 8px 0px rgba(42, 49, 85, 0.1);
    border-radius: 12px;
  }
  .card {
    background: #FFFFFF;

    &__item {
      padding: 14px 16px;
      border-bottom: 1px solid #E6E8EF;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;

      &-title {
        width: 168px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2A3155;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
      }

      &-tools {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        .btn {
          background: #FFFFFF;
          border-radius: 4px;
          padding: 4px 12px;

          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
        }
        .real-question {
          border: 1px solid #6B3CFF;
          color: #6B3CFF;
          margin-right: 8px;
        }
        .basis {
          color: #FF9051;
          border: 1px solid #FF9051;
        }
        .disabled {
          border-color: #d9d9d9;
          color: rgba(0, 0, 0, 0.4);
          background: #f5f5f5;
        }
      }
    }
    &__item:last-child {
      border-bottom: none;
    }
  }
}
</style>
