<!--简答题-->
<template>
  <div class="short-answer">
    <question-header
        :question="question"
        :title="title"
        :question-index="questionIndex"
    />

    <div class="pt_12 tibox ta_l fsz_18" style="overflow-x: scroll">
      <!-- <span>{{ questionIndex[0] }}、</span> -->
      <template v-for="(item,n) in question['例题']">
        <span :key="n" v-if="item[0]=='str'" class="item">{{item[1]}}</span>
        <span :key="n" v-else-if="item[0]=='tex'" class="tex_item" :id="'tex_item_'+n"></span>
        <span :key="n" v-else-if="item[0]=='img'" class="tex_item" :id="'tex_item_'+n">
          <img :src="'data:image/png;base64,'+item[1]" alt="">
        </span>
      </template>
    </div>

    <p class="alert">请在草稿纸作答后点击提交校对</p>

    <template v-if="questionStep === SUBMIT_TYPE.TO_SUBMIT">
      <submit-tools @submit="handleSubmit" :tips="[]" />
    </template>

    <template v-if="questionStep !== SUBMIT_TYPE.TO_SUBMIT">
      <question-summary
          :steps="question['分步'] || []"
          :summary="question['总结']"
          :solution="question['分步解析']"
          :question-step="questionStep"
          @next="handleNextQuestion"
          @on-result="handleResult"
      />

      <div class="h16"/>
      <test-site :site="question['考点']" has-title border/>
    </template>
  </div>
</template>

<script type="application/javascript">
import TestSite from '../TestSite';
import QuestionSummary from '../QuestionSummary'
import QuestionHeader from '../QuestionHeader';
import SubmitTools from '../SubmitTools';

import questionMixin from '@/mixins/questionMixin';
import submitMixin from '@/mixins/submitMixin';
import { SUBMIT_TYPE } from '@/consts';

export default {
  components: {TestSite, QuestionSummary, QuestionHeader, SubmitTools},
  mixins: [questionMixin, submitMixin],
  props: {
    question: {
      type: Object,
      default: {}
    },
    title: {
      type: String,
      default: ''
    },
    questionIndex: {
      type: Array,
      default: [0, 0]
    }
  },
  data () {
    return {
      SUBMIT_TYPE,
      questionStep: SUBMIT_TYPE.TO_SUBMIT,
      isRight: 'false'
    }
  },
  watch: {
    question(newValue, oldValue) {
      this.$nextTick(()=>{
        this.renderQuestionStem(newValue[ '例题' ]);
      })
    }
  },
  methods: {
    handleSubmit () {
      this.questionStep = SUBMIT_TYPE.SHORT_CONFIRM
    },

    handleResult (result) {
      const ret = this.submit({
        exam_status: result,
      });
      this.isRight = result === 'y' ? 'true' : 'false'
      if (ret) {
        this.questionStep = SUBMIT_TYPE.SUBMITTED
      }
    },

    handleNextQuestion () {
      this.$emit('next-question', this.isRight)
    }
  },
  mounted () {
    this.renderQuestionStem(this.question[ '例题' ]);
  }
}
</script>

<style lang="less" scoped>
.stem {
  text-align: left;
  margin-bottom: 16px;

  /deep/ &-index {
    font-size: 16px;
  }

  /deep/ .katex {
    line-height: 40px;
    font-size: 18px;
  }
}
.alert {
  height: 20px;
  font-size: 14px;
  font-family: STSongti-SC-Regular, STSongti-SC;
  font-weight: 400;
  color: #9498AA;
  line-height: 20px;
  margin-top: 13px;
  margin-bottom: 26px;
}

.h16 {
  height: 16px;
}
</style>
